<template>
  <div class="wrap">
      <TopNav />
      <router-view></router-view>
  </div>
</template>

<script>
import TopNav from '@/components/common/TopNav.vue'
// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    TopNav
  },
  mounted() {
    const login  = this.$store.state.login
    
    if(login ==false){
      this.$router.push({name:'Signin'}); 
    }
  },
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import store from '../store'
import axios from 'axios'
import i18n from '../i18n'

Vue.use(VueRouter)
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Main.vue')

// const Staking = () => import(/* webpackChunkName: "staking" */ '../components/mypage/Staking.vue')
// const StakingDetail = () => import(/* webpackChunkName: "staking" */ '../components/mypage/StakingDetail.vue')

const CoinHistory = () => import(/* webpackChunkName: "coin_transaction" */ '../components/dashboard/CoinHistory.vue')
const CoinReceive = () => import(/* webpackChunkName: "coin_transaction" */ '../components/dashboard/CoinReceive.vue')

const Sign = () => import(/* webpackChunkName: "sign" */ '../views/Sign.vue')
const Signin = () => import(/* webpackChunkName: "sign" */ '../components/sign/signin.vue')
const Signup = () => import(/* webpackChunkName: "sign" */ '../components/sign/signup.vue')
const FindPassword = () => import(/* webpackChunkName: "sign" */ '../components/sign/FindPassword.vue')
const SignIssue = () => import(/* webpackChunkName: "sign" */ '../components/sign/SignIssue.vue')

const Email = () => import( /* webpackChunkName: "sign" */ '../components/pages/Email.vue')

const SubPage = () => import( /*webpackChunkName: "subpage" */ '../views/SubPage.vue')
const Menu = () => import(/* webpackChunkName: "subpage" */ '../components/common/Menu.vue')

const Security = () => import( /* webpackChunkName: "setting" */ '../components/setting/Security.vue')
const Coin = () => import( /* webpackChunkName: "setting" */ '../components/setting/Coin.vue')
const Language = () => import(/* webpackChunkName: "setting" */ '../components/setting/Language.vue')


const NoticeList = () => import(/* webpackChunkName: "notice" */ '../components/notice/NoticeList.vue')
const NoticeDetail = () => import(/* webpackChunkName: "notice" */ '../components/notice/NoticeDetail.vue')

const FaqList = () => import(/* webpackChunkName: "faq" */ '../components/notice/FaqList.vue')
const FaqDetail = () => import(/* webpackChunkName: "faq" */ '../components/notice/FaqDetail.vue')


const InquiryList = () => import(/* webpackChunkName: "mypage" */ '../components/mypage/InquiryList.vue')
const InquiryDetail = () => import(/* webpackChunkName: "mypage" */ '../components/mypage/InquiryDetail.vue')
const InquiryForm = () => import(/* webpackChunkName: "mypage" */ '../components/mypage/InquiryForm.vue')

const MyInfo = () => import(/* webpackChunkName: "mypage" */ '../components/mypage/MyInfo.vue')

const ChgLoginPw = () => import(/* webpackChunkName: "mypage" */ '../components/mypage/ChgLoginPw.vue')
const ChgTransactionPw = () => import( /* webpackChunkName: "mypage" */ '../components/mypage/ChgTransactionPw.vue')
const CheckPrivateKey = () => import( /* webpackChunkName: "mypage" */ '../components/mypage/CheckPrivatekey.vue')

const MyWallet = () => import( /* webpackChunkName: "mypage" */ '../components/mypage/MyWallet.vue')
const Unsubscribe = () => import( /* webpackChunkName: "mypage" */ '../components/mypage/OptOut.vue')

const Restore = () => import( /* webpackChunkName: "mypage" */ '../components/mypage/Restore.vue')

const Withdrawals = () => import( /* webpackChunkName: "transaction" */ '../components/transaction/Withdrawals.vue')
const WithdrawalDetail = () => import(/* webpackChunkName: "transaction" */ '../components/transaction/WithdrawalDetail.vue')
const PwInput = () => import(/* webpackChunkName: "transaction" */ '../components/transaction/PwInput.vue')

const TransactionDetail = () => import(/* webpackChunkName: "transaction" */ '../components/transaction/TransactionDetail.vue')
const Trade = () => import(/* webpackChunkName: "transaction" */ '../components/transaction/Trade.vue')

const NFTLIST = () => import(/* webpackChunkName: "nft" */ '../components/transaction/NFT.vue')
const NFTDetail = () => import(/* webpackChunkName: "nft" */ '../components/transaction/NFTDetail.vue')

const KYC = () => import( /* webpackChunkName: "kyc" */ '../components/mypage/Kyc.vue')
const KYCEdit = () => import( /* webpackChunkName: "kyc" */ '../components/mypage/KycEdit.vue')
const KYCStatus = () => import(/* webpackChunkName: "kyc" */ '../components/mypage/KycStatus.vue')
const Terms = () => import(/* webpackChunkName: "kyc" */ '../components/common/terms.vue')

const Package = () => import(/* webpackChunkName: "package" */ '../components/package/Package.vue')
const PackageHistory = () => import(/* webpackChunkName: "package" */ '../components/package/PackageHistory.vue')
const PackagePayment = () => import(/* webpackChunkName: "package" */ '../components/package/PackagePayment.vue')

const Mnemonic = () => import( /* webpackChunkName: "authentication" */ '../components/authentication/Mnemonic.vue')
const ConfirmMnemonic = () => import( /* webpackChunkName: "authentication" */ '../components/authentication/ConfirmMnemonic.vue')

const routes = [
  {
    path: '/',
    name: 'Sign',
    component: Sign,
    redirect: `/${i18n.locale}/signin`,
    alias: '/:lang/signin',
    children: [
      {
        path: '/:lang/signin',
        name: 'Signin',
        component: Signin
      }, 
      {
        path: '/:lang/signup',
        name: 'Signup',
        component: Signup
      },
      {
        path: '/:lang/find-password',
        name: 'FindPassword',
        component: FindPassword
      },
      {
        path: '/:lang/certification',
        name: 'certification',
        component: Email
      },
      {
        path: '/:lang/page/terms',
        name: 'Terms',
        component: Terms
      },
      {
        path: '/:lang/sign-issue',
        name: 'Issue',
        component: SignIssue
      },
    ]
  }, 
  {
    path: '/:lang',
    name: 'Home',
    component: Home,
    redirect: `/${i18n.locale}/dashboard`,
    children: [{
      path: '/:lang/dashboard',
      name: 'Dashboard',
      component: Dashboard
    }, ]
  }, 
  {
    path: '/:lang/page/:idx',
    name: 'SubPage',
    component: SubPage,
    children: [
      {
        path: '/:lang/page/menu',
        name: 'menu',
        component: Menu,
      },
      {
        path: '/:lang/page/notice-list',
        name: 'NoticeList',
        component: NoticeList
      },
      {
        path: '/:lang/page/notice/:index',
        name: 'NoticeDetail',
        component: NoticeDetail
      },
      {
        path: '/:lang/page/faq-list',
        name: 'FaqList',
        component: FaqList
      },
      {
        path: '/:lang/page/faq/:index',
        name: 'FaqDetail',
        component: FaqDetail
      },
      {
        path: '/:lang/page/inquiry-list',
        name: 'InquiryList',
        component: InquiryList
      },
      {
        path: '/:lang/page/inquiry/:index',
        name: 'InquiryDetail',
        component: InquiryDetail
      },
      {
        path: '/:lang/page/inquiry-form',
        name: 'InquiryForm',
        component: InquiryForm
      }, 
      {
        path: '/:lang/page/inquiry-form/:index',
        name: 'InquiryEdit',
        component: InquiryForm
      },
      {
        path: '/:lang/page/myinfo',
        name: 'MyInfo',
        component: MyInfo
      },
      {
        path: '/:lang/page/chg-login-pw',
        name: 'ChgLoginPw',
        component: ChgLoginPw
      }, 
      {
        path: '/:lang/page/chg-transaction-pw',
        name: 'ChgTransactionPw',
        component: ChgTransactionPw
      }, 
      {
        path: '/:lang/page/chk-private-key',
        name: 'CheckPrivateKey',
        component: CheckPrivateKey
      },
      // {
      //   path: '/:lang/page/mystaking',
      //   name: 'Staking',
      //   component: Staking
      // },
      // {
      //   path: '/:lang/page/mystaking/:idx',
      //   name: 'StakingDetail',
      //   component: StakingDetail
      // },
      {
        path: '/:lang/page/coin/:net/:idx',
        name: 'CoinHistory',
        component: CoinHistory
      },
      {
        path: '/:lang/page/coin/:net/:idx/receive',
        name: 'CoinReceive',
        component: CoinReceive
      },
      {
        path: '/:lang/page/mywallet',
        name: 'MyWallet',
        component: MyWallet
      },
      {
        path: '/:lang/page/unsubscribe',
        name: 'Unsubscribe',
        component: Unsubscribe
      },
      {
        path: '/:lang/page/security',
        name: 'Security',
        component: Security
      }, {
        path: '/:lang/page/coin',
        name: 'Coin',
        component: Coin
      },
      {
        path: '/:lang/page/language',
        name: 'language',
        component: Language
      },
      {
        path: '/:lang/page/package',
        name: 'Package',
        component: Package
      }, 
      {
        path: '/:lang/page/package/:select',
        name: 'Package',
        component: Package
      },
      {
        path: '/:lang/page/package-history',
        name: 'PackageHistory',
        component: PackageHistory
      },
      {
        path: '/:lang/page/package-payment/:index',
        name: 'PackagePayment',
        component: PackagePayment
      },
      {
        path: '/:lang/page/withdrawals/:net/:token',
        name: 'Withdrawals',
        component: Withdrawals
      },
      {
        path: '/:lang/page/withdrawal/send/:net/:token/:address',
        name: 'WithdrawalDetail',
        component: WithdrawalDetail
      },
      {
        path: '/:lang/page/pwinput/:token/:amount/:address',
        name: 'PwInput',
        component: PwInput
      },
      {
        path: '/:lang/page/transaction-detail',
        name: 'TransactionDetail',
        component: TransactionDetail
      },
      {
        path: '/:lang/page/trade',
        name: 'Trade',
        component: Trade
      },
      {
        path: '/:lang/page/kyc',
        name: 'KYC',
        component: KYC
      },
      {
        path: '/:lang/page/kyc/edit',
        name: 'KYCEdit',
        component: KYCEdit
      },
      {
        path: '/:lang/page/kyc/status/:check',
        name: 'KYC-status',
        component: KYCStatus
      }, 
      {
        path: '/:lang/page/mnemonic',
        name: 'mnemonic',
        component: Mnemonic
      }, {
        path: '/:lang/page/mnemonic-confirm',
        name: 'mnemonic-confirm',
        component: ConfirmMnemonic
      }, {
        path: '/:lang/page/wallet-restore',
        name: 'wallet-restore',
        component: Restore
      }, {
        path: '/:lang/page/nft',
        name: 'nft-list',
        component: NFTLIST
      },{
        path: '/:lang/page/nft/:index',
        name: 'nft-detail',
        component: NFTDetail
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})


router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }

})
router.beforeEach((to, from, next) => {
  axios.defaults.headers.post['authorization'] = store.state.token;
  next();
})


export default router

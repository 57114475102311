<template>
  <div id="app">
      <!-- <transition name="slide-bottom" mode="out-in"> -->
        <div class="wrap"><router-view/></div>
      <!-- </transition> -->
  </div>
</template>
<script>
export default {
  methods: {    
    GetSync(){
      const api = this.$store.state.baseURL;
      this.$http.post(`${api}/member/sync`,{})
    },
  },
  mounted() {
    window.addEventListener("load", function() {
        var viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute("content", viewport.content + ", height=" + window.innerHeight);
    })
    if(this.$store.state.login ==true){
      this.GetSync();
    }
  },
}
</script>

<style src="@/assets/css/all.css"></style>
<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&family=Roboto:wght@300;400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap');
body{
  font-size: 1.1rem;
}
#app {
  font-family: 'Roboto', 'Noto Sans KR', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  min-width: 320px;
  overflow-x: auto;
}
.form-control,.btn{
  font-size: 1em;
}
.wrap>div:nth-of-type(2){
  position: absolute;
  top: 50px;
  width: 100%;
  /* left: 50%;
  transform: translateX(-50%); */
}
small, .small{
  font-size: 85%;
}
ul{
    padding: 0;
    margin: 0;
}
li,ol,dl{
  list-style: none;
}
a{
  color: #333;
}
a,a:hover,a:active{
  text-decoration: none !important;
}
.flex{
  display: flex;
}
.main_txt{
  color: #4B67F9 !important;
}
.gray_txt{
  color: #999;
}
.pale_txt{
  color: #bbb !important;
}
.pale_txt2{
  color: #ddd;
}
.white_txt{
  color: #fff !important;
}
.icon-wrap{
  position: relative;
}
.icon-wrap i{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 5rem;
  color:#4B67F9;
}
.badge{
  padding: 0.3em 0.4em;
  vertical-align: text-bottom;
}

.badge-gray{
  color: #fff;
  background-color: #999;
}

.break-all{
  word-break: break-all;
}

.wrap{
  overflow: hidden;
  overflow-x: hidden;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  height: 100vh;
  background-color: #F5F6FA;
  /* padding-top: 50px; */
}

.container{
  max-width: 1024px;
  padding-top: 5px;
  padding-bottom: 15px;
  border-radius: 25px 25px 0 0;
  background-color: #fff;

  min-height: calc(100vh - 50px);
  padding-top: 35px;
  
  overflow: auto;
  height: calc(100vh - 50px);
}

.overflow_auto{
  overflow: auto;
  height: 100%;
}

.empty_box{
  width:100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-100%);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.empty_box i{
  font-size: 4rem;
  margin-bottom: .3em;
  color: #d7d7d7;
}
.empty_box .tit{
  color: #777;
  font-weight: 500;
  font-size: 1.2em;
}

.tab_tit{
  font-size: 15px;
}

/* 게이지 */
.gauge_box{
  background-color: #eaeaea;
  width: 100%;
  height: 4px;
  position: relative;
  border-radius: 4px;
}
.gauge{
  background-color: #4B67F9;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 4px;
  max-width: 100%;
}

/* 작은 안내 문구 */

.warnning_txt_box{
    font-size: 12px;
    color: #bbb;
}
.warnning_txt_box ul li{
    list-style: disc;
}

/* sign form */
.has_fixed_bottom_box{
  padding-bottom: 54px;
}
.has_fixed_bottom_box .container{
  min-height: calc(100vh - 50px - 54px);
  height: calc(100vh - 50px - 54px);
}
.fixed_bottom_box{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    max-width: 1024px;
    left: 50%;
    transform: translateX(-50%);
}

.fixed_bottom_box{
    /* background-color: #f7fafb; */
    background-color: #fff;
}
.top_txt_box{
  font-weight: bold;
}
.top_txt_box h3{
  font-weight: bold;
}
.top_txt_box p{
    font-size:  0.95em;
    margin-top: 15px;
}

.top_txt_box+.form_box label{
  font-weight: bold !important;
  font-size: .95em !important;
}

.top_txt_box+.form_box input{
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 10px;
}

.form-group.line input{
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #eaeaea;
    background-color: transparent;
    padding: 5px;
}

input::placeholder{
  font-size: .875em;
}
.form-group.line input::placeholder{
    font-size: .875em;
    color: #aaa;
    padding-left: 0;
}
.form-group.line label{
    font-size: 0.8em;
    font-weight: 400;
    color: #4B67F9;
    margin-bottom: 0;
}
.form-group.line .form-control:focus{
    box-shadow: none;
    border-bottom: 1px solid #4B67F9;
}
.form-group .l_btn{
    position: relative;
}
.form-group .l_btn input{
    padding-left: 80px;
    box-sizing: border-box;
}
.form-group .l_btn input+button{
    position: absolute;
    width:80px;
    height: 100%;
    font-size: 14px;
    left: 0;
    bottom: 0;
    color: #4B67F9;
    text-align: left;
}
.form-group .r_btn{
    position: relative;
}
.form-group .r_btn input{
    padding-right: 60px;
    box-sizing: border-box;
}
.form-group .r_btn input+button{
    position: absolute;
    width:60px;
    font-size: 14px;
    /* font-weight: bold; */
    right: 0;
    bottom: 0;
    color: #4B67F9;
    text-align: right;
}
.form-group .r_btn input+button:focus{
  box-shadow: none;
}
.form-control[readonly]:focus,.form-control[readonly]:active{
    border-bottom: none;
    background-color: #f7f9fb;
}
.btn-primary{
  background: #4B67F9;
  background: -webkit-linear-gradient(to left, #4B67F9, #025ed1);
  background: linear-gradient(to left, #4B67F9, #025ed1);
  border-color: #025ed1 !important;
}

/* 인라인 폼 */
.form-group.inline{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
}
.form-group.inline label{
  flex-shrink: 0;
  /* font-size: 14px;
  font-weight: bold; */
  /* font-size: 13px; */
  font-size: 0.8em;
  font-weight: 400;
  color: #4B67F9;
  margin-bottom: 0;
}
.form-group.inline .form-control{
  background-color: transparent;
  border: none;
}

/* 셀렉트폼 */

select { 
    -webkit-appearance: none; /* 네이티브 외형 감추기 */ 
    -moz-appearance: none; 
    appearance: none;
    background: url(assets/img/icon/down-chevron.svg) no-repeat calc(100% - 15px) 50%;
    background-size: 12px;
}
select.default{
    -webkit-appearance: none; /* 네이티브 외형 감추기 */ 
    -moz-appearance: none; 
    appearance: none;
    background: url(assets/img/icon/down-chevron-dark.svg) no-repeat calc(100% - 15px) 50%;
    background-size: 12px;
    background-color: #fff;
}
select::-ms-expand { display: none; }

select.form-control.primary{
    background-color: #4B67F9;
    /* height: 48px; */
    border: none;
    color: #fff;
}

/* qrcode */
.qrcode_box{
    /* margin-bottom: 50px; */
    margin-bottom: 30px;
}
.qrcode_box>*{
    width: 50%;
    /* max-width: 220px; */
    max-width: 180px;
    display: block;
    margin: 0 auto;
}


/* 게시판(공지사항) */


.underline{
  border-bottom: 1px solid #eaeaea;
}

.board_tit_box{
  padding-bottom: 1em;
  margin-bottom: 0;
}

.board_tit_box li{
  padding: 1em 0;
  border-bottom: 1px solid #eaeaea;

  /* padding: 1em;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14); */
}
.board_tit_box li:first-child{
  padding-top: 0;
}
.board_tit_box li:last-child{
  border-bottom: 0;
}
.board_tit_box a{
  color: #333;
}
.board_tit_box p{
  margin-bottom: 0;
}
.board_tit_box .tit{
  /* font-size: 15px; */
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.board_tit_box a{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.board_tit_box a i{
  color: rgb(200,200,200);
}
.board_tit_box .date{
  /* font-size: 13px; */
  font-size: 0.9rem;
  color: #bbb;
  font-weight: 400;
  vertical-align: bottom;
}
.board_content_box{
  /* font-size: 14px; */
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3;
  white-space: pre-line;
  font-family: inherit;
  margin-bottom: 0;
  padding-bottom: 1em;
}
/* 교환 및 목록 */

.txt_icon{
  display: block;
  width: 30px;
  height: 30px;
  background-color: #4B67F9;
  color: #fff;
  text-align: center;
  flex-shrink: 0;
  border-radius: 100%;
  font-size: 12px;
  line-height: 30px;
  margin-right: 10px;
}

.txt_icon.send{
  background-color: #4B67F9;
}

.txt_icon.receive{
  background-color: #ffb553;
}
.history_box{
  /* font-size: 14px; */
  font-size: 0.9em;
}
.history_box p{
  margin-bottom: 0;
}
.history_box .left{
  width: 50%;
}
.history_box .right{
  width: calc(50% - 40px);
  text-align: right;
  font-weight: 500;
}
.history_box .addr{
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}
.history_box .date{
  font-size: 12px;
}

.infoPp{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}
.infoPp .dimm{
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.infoPp .content{
  position: fixed;
  background-color: #fff;
  padding: 15px;
  border-radius: 15px 15px 0 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1024px;
  max-height: 75vh;
  overflow: auto;
  animation: slideUp 0.3s;
}
@keyframes slideUp{
  from {
      bottom: -100%;
  }

  to {
      bottom: 0;
  }
}

/* 보유금 박스 */

.wallet{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.wallet h4{
    text-transform: uppercase;
    font-size: 28px;
    letter-spacing: 2px;
}
.wallet h5{
    font-size: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 8px;
    margin: 0 auto;
    margin-top: 2px;
    letter-spacing: 0.5px;
}

/* 거래비밀번호 입력창 */

.tr_pw_box .fixed_bottom_box{
    background-color: #f7fafb !important;
}
.opacity-0{
    opacity: 0;
}
input.opacity-0{
    width: 0;
    height: 0;
    border: 0;
    padding: 0;
}
.tr_pw_box label{
    width: 100%;
    text-align: center;
}
.pw_form{
    display: flex;
    justify-content: center;
}
.pw_form li{
    background-color: #eaeaea;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    margin-right: 15px;
}
.pw_form li.fill{
    background-color: #4B67F9;
}

/* border-line input */
.form-group.border-line .form-control{
    border: 1px solid #ddd;
    height: 48px;
    border-radius: 5px;
    background-color: #fff;
    font-weight: bold;
}
.form-group.border-line .form-control[type=password]{
    letter-spacing: 7px;
}
.form-group.border-line.primary .form-control{
    border: 2px solid #4B67F9;
}
.form-group.border-line .form-control:focus{
    border: 2px solid #4B67F9;
    box-shadow: none;
}
.form-group.border-line .form-control:disabled{
    background-color: #fff;
}


/* swal 알림창 */
#swal2-content{
  font-size: 15px;
  line-height: 1.6;
}
.swal2-header .swal2-title{
  font-size: 18px;
  margin: 0 0 1em;
}
.swal2-actions .swal2-styled.swal2-confirm{
  background-color: #4B67F9;
}
.swal2-actions .swal2-styled.swal2-confirm, .swal2-actions .swal2-styled.swal2-cancel{
  font-size: 1em;
  font-weight: normal;
}
.swal2-actions .swal2-styled{
  padding: .45em 1.4em;
}

/* 보안코드 */

.secu_code_box .code{
    height: 66px;
    text-align: center;
    letter-spacing: 6px;
    font-size: 23px;
    font-weight: bold;
    background-color: #f9f9f9;
    line-height: 61px;
    font-style: italic;
    color: #333;
    
    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.countdown{
  color: #72a4ff;
}

.footer{
  font-size: 0.68em;
  padding: 0 10px 60px 10px;
  color: #ccc;
  text-align: center;
}
/* .footer::before{
  content: "";
  width: 30px;
  height: 1px;
  background-color: #e5e5e5;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: -25px;
} */

/* 스테이킹 뱃지 */

.status_badge{
    border-radius: 20px;
    padding: 2px 10px;
    /* color: #fff; */
    font-size: 0.78em;
    margin-right: -5px;

    border: 1px solid;
    background: transparent;
    font-weight: normal;
}
.status_badge._01{
    border-color: #aaa;
    color: #aaa;
}
.status_badge._02{
    border-color: #0984e4;
    color: #0984e4;
}
.status_badge._03{
    border-color: #e26363;
    color: #e26363;
}
.status_badge._04{
    border-color: #d8af28;
    color: #d8af28;
}
.status_badge._05{
    border-color: #f74b4b;
    color: #e26363;
}

.cutting_line{
  border-style: dashed;
}

</style>

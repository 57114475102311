import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: process.env.VUE_APP_API_URL,
    token: '',
    email: '',
    social: '',
    login: false,
    loginToken: true,
    headerTitle: '',
    prevPage:'',
    pageDashboard: '',
    AWS: {
			URL: process.env.VUE_APP_AWSURL,
			albumBucketName: process.env.VUE_APP_AWSBUCKETNAME,
			bucketRegion: process.env.VUE_APP_AWSBUCKETREGION,
			IdentityPoolId: process.env.VUE_APP_AWSPOOLID,
    },
    FOOTER: {
      LOGO: "GNR",
      CEO: "GOLDNRUSH",
      EMAIL: "support@goldnrush.com"
    },
    APP: {
      GOOGLE: '',
      APPLE: ''
    }
  },
  plugins: [createPersistedState()],
  mutations: {
    chgTitle(state,title){
      state.headerTitle = title
    },
    backPage(state,prevP){
      state.prevPage = prevP
    },
    backDashboard(state, prevP) {
      state.pageDashboard = prevP
    },
    SETMEMBER(state, mem_info) {
      state.login = true;
      state.token = mem_info.token;
      state.email = mem_info.email;
      state.social = mem_info.social;
    },
    SETLOGOUT(state) {
      state.token = '';
      state.email = '';
      state.social = '';
      state.login = false;
      state.loginToken = true;
    },
    editLoginToken(state) {
      state.loginToken = false;
    },
  },
  actions: {
    SETMEMBER ({commit}, {mem_info}) {
      commit('SETMEMBER', mem_info);
    },
    SETLOGOUT ({commit}) {
      commit('SETLOGOUT');
    },
    setLoginToken({commit}){
      commit('editLoginToken');
    },
    
  },
  modules: {
  }
})

import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import i18n from './i18n'
import VueQrcodeReader from "vue-qrcode-reader";
import VueSimpleAlert from "vue-simple-alert";
import VueDaumPostcode from "vue-daum-postcode"
import VueElementLoading from 'vue-element-loading'
import VueCookies from 'vue-cookies'

require("dotenv").config();

Vue.config.productionTip = false

// axios
import axios from "axios"
Vue.prototype.$http = axios
Vue.prototype.$jquery = $

// clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Import stylesheet
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(VueCookies, { expires: '30d'})

Vue.use(Loading);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSimpleAlert);
Vue.use(VueQrcodeReader);
Vue.use(VueDaumPostcode)
Vue.component('VueElementLoading', VueElementLoading)

router.beforeEach((to, from, next) => {

  let language = to.params.lang;
  if (!language) {
    language = 'ko'
  }

  i18n.locale = language
  next()
})

var vm = new Vue({
  directives: {
    focus: {
      // 연결되어 있는 요소가 DOM에 추가될 때
      inserted: function (el) {
        el.focus() // 요소에 초점을 맞춤
      }
    }
  },
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.app = vm;

<template>
    <div id="topNav">
        <img src="@/assets/img/logo/logo_white.png" alt="" height="18">
        <router-link :to="`/${$i18n.locale}/page/menu`" class="btn setting ml-auto"><i class="far fa-bars h5"></i></router-link>
    </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
}
</script>

<style scoped>
#topNav{
    /* position: relative; */
    position: fixed;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1024px;
    z-index: 99;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5em 1em;
    box-sizing: border-box;
    background-color: #4b67f9;
}
.setting{
    padding: 0;
    font-size: 1.5em;
    outline: none;
    color: #fff;
}
.setting:focus{
    box-shadow: none;
}
</style>